import './App.css';

function App() {
  return (
    <blockquote>"The forest answers in the same way one shouts at it."
			<footer>&mdash;
				<cite>
					Finnish saying
				</cite>
			</footer>
		</blockquote>
  );
}

export default App;
